import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'

const Innkjopsradgiver = props => {
  const { instance, lang } = props.pageContext
  const title = `Innkjøpsrådgiver | Innkjøpskonsulent | ${instance.title}`
  const description =
    'Innkjøpsrådgiver? Ta kontakt med Cost Partner. Vi har dyktige innkjøpskonsulenter som bistår din bedrift.'

  return (
    <Layout {...props}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <div className="main-content content-module">
        <Container textWidth>
          <h1>Innkjøpsrådgiver og innkjøpskonsulent</h1>
          <p>
            <strong>
              Behøver din bedrift en innkjøpsrådgiver? Det kan vi i Cost Partner
              Norge AS bistå deg med. Vi har dyktige innkjøpskonsulenter som
              jobber for at din bedrift skal spare mest mulig.
            </strong>
          </p>
          <p>
            <Link to={`/${instance.id}/${lang}/contact/`}>Ta kontakt</Link>
          </p>
          <p>
            Ta kontakt i dag for en uforpliktende prat, eller les videre dersom
            du vil vite hvordan din bedrift enkelt kan oppnå besparelser på
            opptil 30% eller mer.
          </p>
          <h2>Hvorfor bruke en innkjøpsrådgiver?</h2>
          <p>
            En innkjøpsrådgiver bistår din bedrift med å analysere kostnader,
            samt fremforhandle bedre innkjøpsavtaler.
          </p>
          <p>
            Ved å bruke en innkjøpskonsulent fra Cost Partner, kan vi hjelpe deg
            med å se på hvilke områder du kan hente størst kostnadsbesparelse.
            For mange bedrifter vil dette være ved å forhandle frem gunstigere
            innkjøpsavtaler.
          </p>
          <p>
            Når du bruker Cost Partner som din innkjøpsrådgiver, vil vi sørge
            for at du oppnår kostnadsbesparelser der vi ser det er mulig. Ta
            gjerne kontakt for en uforpliktende prat om hvordan vi kan bistå din
            virksomhet.
          </p>
          <h2>Diverse kostnadskategorier</h2>
          <p>
            Den{' '}
            <Link to={`/${instance.id}/${lang}/about/`}>
              gjennomsnittlige besparelsen ligger på ca 30%
            </Link>{' '}
            blant våre kunder det siste året. Dette er helt kostnads- og
            risikofritt fordi vi kun beregner honorar når besparelse oppnås. Ved
            å velge oss som din innkjøpsrådgiver, kan vi hjelpe deg med din
            bedrifts kostnadsbesparelser.
          </p>
          <p>Vi utfører blant annet analyser innen kostnadskategorier som:</p>
          <ul>
            <li>
              Facility services: Renhold, kantinedrift, drikkeautomater,
              renovasjon, sikkerhet, energi, serviceavtaler
            </li>
            <li>
              Forbruksmateriell: Kontorrekvisita, datarekvisita,
              driftsrekvisita, trykksaker, emballasje, arbeidstøy- og vern,
              industrirekvisita
            </li>
            <li>Tele og IT: Telefon, kontormaskiner</li>
            <li>Transport: Bil, tog, sjøfrakt, flyfrakt</li>
            <li>Annet: Billeasing, banktjenester</li>
          </ul>
          <p>
            Les mer:{' '}
            <Link to={`/${instance.id}/${lang}/kostnadsanalyser/`}>
              Kostnadsanalyser
            </Link>
          </p>

          <h2>Om Cost Partner Norge AS</h2>
          <p>
            Cost Partner AS er din partner i kostnadsbesparelse og
            fremforhandling av innkjøpsavtaler. Vi er et konsulentselskap som
            jobber med besparelser på alle indirekte og administrative
            kostnader.
          </p>
          <p>
            Ved å gjennomføre omfattende analyser og ved hjelp av vår unike
            database med blant annet informasjon om priser, produkter og
            leverandører, gir vi våre kunder besparelser de ikke selv kan oppnå.
          </p>
          <p>
            Ved å bruke oss vil du også kunne oppleve tilleggseffekter som
            reduksjon av antall leverandører og fakturamengde, forbedrede
            innkjøpsrutiner, økt kjøpslojalitet til valgte leverandører,
            optimalisering av produkter kvalitet og service, samt overføring av
            kompetanse.
          </p>

          <h3>Lang kundeliste</h3>
          <p>
            Vi har over tid opparbeidet oss en lang kundeliste med fornøyde
            kunder som har oppnådd høy kostnadsbesparelse ved hjelp av
            innkjøpskonsulenter fra Cost Partner.
          </p>
          <p>
            Vi er stolte av å ha samarbeidet med store norske selskap som Nokas,
            Mester Grønn, DNB, Power, Home & Cottage, XXL, Nikita Hair, Astrup
            Fearnley og Vitusapotek. Også Kid Interiør fikk ned sine facility
            service kostnader 40% ved å samarbeide med oss.
          </p>

          <h2>Ta kontakt med oss</h2>
          <p>
            Vi tar ikke honorar dersom vi ikke oppnår besparelse. Vi kaller
            dette for no cure, no pay, som vil si at vi kun tar ut honorar av
            det kunden har spart etter et år med Cost Parter som partner. Kunden
            skal komme til oss og være trygg på at vi finner frem til økonomiske
            løsninger og besparelser.
          </p>
          <p>
            Høres dette ut som noe for deg? Ta kontakt for en uforpliktende
            samtale. Vi forklarer deg gjerne mer om hva vi driver med dersom du
            skulle ha spørsmål. Plukk opp telefonen og start sparingen i dag!
          </p>
          <p>
            <Link to={`/${instance.id}/${lang}/contact/`}>Ta kontakt</Link>
          </p>
        </Container>
      </div>
    </Layout>
  )
}

export default Innkjopsradgiver
